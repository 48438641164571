<template>
    <div>
      <vue-element-loading
      :active="appLoading"
      spinner="bar-fade-scale"
      color="#4480C5"
      size="60"
      is-full-screen
    />
        <v-layout wrap justify-center>
            <v-flex pt-5 xs12 sm12 md12 lg11 xl12 >
        
              <!-- Header Section -->
              <v-layout wrap px-lg-0 px-md-10 px-sm-8 px-3  class="my-3">
                <v-flex xs12 sm10 md10 lg10 text-left align-center pt-2 class="headingChurch">Blessings & Miracles</v-flex>
              </v-layout>

              <v-layout wrap justify-center pt-2 pb-6 v-if="miracleView">
                <v-flex xs11 sm11 md11 lg12 align-self-center>
                  <v-card class="custom-card">
                    <v-card-title class="card-header">
                        <v-layout wrap justify-center>
                            <v-flex xs12>
                              <v-layout wrap justify-center py-3>
                                <v-flex xs4 px-4 v-if="miracleView.photo">
                                  <v-img v-bind:src="mediaURL + miracleView.photo" cover ></v-img>

                                  <!-- <v-img src="@/assets/images/blessing.png" cover class="mx-auto" style="max-width: 100%; height: 400px;"></v-img> -->
                                </v-flex>
                                <v-flex xs12>
                                  <v-layout wrap justify-center>
                                    <v-flex x12 text-center pt-3>
                                      <span 
                                      :style="{
                                       
                                        'overflow-wrap': 'break-word',
                                        'word-break': 'break-word',
                                       
                                      }"
                                      
                                      style="
                                          font-family: interbold;
                                          color: #1C6DC1;
                                          font-size: 20px;
                                        ">{{
                                            miracleView.name
                                            }}</span><br>
                      
                                      
                                    </v-flex>
                      
                      
                      
                                    <v-flex xs12 px-4 pt-3 text-justify>
                                      <span style="font-family:interregular; text-align: justify; font-size: 16px;" v-html="miracleView.description"></span>
                                    </v-flex>
                                  </v-layout>
                                </v-flex>
                            </v-layout>
                            </v-flex>
                          </v-layout>
                    </v-card-title>
                    <v-divider></v-divider>
      
      
      
                    <!-- Custom HTML Table -->
                  
      
                  </v-card>
                </v-flex>
              </v-layout>
            </v-flex>
        </v-layout>
    </div>
  </template>

  <script>
import axios from 'axios';
export default {
  components: {
  },
  data() {
    return {
        miracleView:{},
        appLoading: false,
      
    };
  },
 
  mounted() {
  this.getData(); // Fetch data when component mounts
},
 
  methods: {
    getData() {
      this.appLoading = true;
      axios({
        url: "/get/miracle",
        method: "GET",
        params: {
          id: this.$route.query.id,
        },
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.miracleView = response.data.data;
          //this.examDetails = response.data.examSettings;
          //this.isExam = response.data;
          // this.name = response.data.data.name;
          // this.categoryArray = response.data.data.category;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  }
};
</script>